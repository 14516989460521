@tailwind base;
@tailwind components;
@tailwind utilities;

.cursor-copy {
  cursor: copy;
}

body {
  background-color: #072333;
}
/*
button {
  background-color: purple;
  color: ;
} */

#subjectField:focus, #emailField:focus { 
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 10px #719ECE;
}
textarea:focus { 
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 10px #719ECE;
}

#percent {
  color: #7496C4;
}

.fadein-left {
  animation: fadein-left 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes fadein-left {
  0% {
    right: -100%;
  }
  100% {
    right: 1rem;
  }
}

.top-95 {
  top: 95%;
}

::-webkit-scrollbar {
  width: 0px;
}
